import { Popover } from "@headlessui/react";
import React from "react";
import { Link } from "react-router-dom";
import { SignUpButton } from "./AboutPage";
import { Fragment } from "react";
import { Disclosure, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";

export const NavBar = () => {
  return (
    <nav className="hidden md:flex fixed z-50 w-full px-2 md:px-24 py-4 md:py-9 h-24 bg-white items-center justify-between shadow">
      <Link className="flex items-center" to="/">
        <img className="hidden md:block" src="/logo.svg" alt="" />
        <h1 className="hidden md:block ml-2 text-2xl font-bold">
          Cellebrity.bio
        </h1>
        <img className="md:hidden" src="/logo.svg" alt="" />

        {/* <img className="hidden md:block" src="/cellebritybio.svg" alt="" /> */}
        {/* <img className="md:hidden" src="/logo_icon.svg" alt="" /> */}
      </Link>
      <div className="hidden md:flex items-center space-x-4">
        <a
          className="hidden md:block text-base"
          href="mailto:info@cellebrity.com"
        >
          info@cellebrity.bio
        </a>
        <SignUpButton />
        <a
          className="hidden lg:block"
          href="https://twitter.com/cellebritybio"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="" src="/about/twitter.svg" alt="twitter logo" />
        </a>
      </div>
    </nav>
  );
};

export function MobileNavBar() {
  return (
    <div className="fixed z-50 w-full md:hidden">
      <Popover as="nav" className="relative">
        {({ open }) => (
          <>
            <div className={`${open ? "bg-white" : "bg-white"} px-2 shadow`}>
              <div className="relative flex items-center justify-between h-16">
                <Link className="flex items-center" to="/">
                  <img className="" src="/logo.svg" alt="" />
                  <h1 className="ml-2 text-2xl font-bold">Cellebrity.bio</h1>
                </Link>

                <div className="absolute inset-y-0 right-4 flex items-center">
                  <Popover.Button
                    className={`
                 rounded-md inline-flex items-center text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                  >
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Popover.Button>
                </div>
              </div>
            </div>
            {/*  */}
            <Transition
              as={Fragment}
              show={open}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                static
                className="pt-5 pb-56 bg-gradient-to-b from-white via-gray-50"
              >
                <div className="pt-2 px-6 space-y-5 text-sm">
                  <Popover.Button
                    className="px-20 py-3 w-full text-center text-sm rounded-full shadow hover:shadow-xl"
                    style={{
                      backgroundImage: "url(/about_button_background.png)",
                      lineHeight: "160%",
                    }}
                  >
                    <Link
                      className="block w-full text-white"
                      to="/celeb-sign-up"
                    >
                      SIGN UP as a Cellebrity
                    </Link>
                    {/*  */}
                  </Popover.Button>
                  <a
                    href="mailto:info@cellebrity.bio"
                    className="block px-20 py-3 bg-white text-center rounded-full shadow hover:shadow-xl"
                  >
                    Contact us
                  </a>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}

/**
 * @deprecated
 * @returns
 */
export const MobileNavBar2 = () => {
  return (
    <Disclosure
      as="nav"
      className="fixed z-50 w-full md:hidden"
      // style={{ background: "transparent" }}
    >
      {({ open }) => (
        <>
          <div className={`${open ? "bg-white" : null} px-2`}>
            <div className="relative flex items-center justify-between h-16">
              <Link className="block" to="/">
                <img className="" src="/logo.svg" alt="" />
              </Link>

              <div className="absolute inset-y-0 right-4 flex items-center">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-full bg-gray-50 text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="pb-24 bg-gradient-to-b from-white via-gray-50">
            <div className="pt-2 px-6 space-y-1">
              {/* <SignUpButton /> */}
              <Link
                to="/celeb-sign-up"
                className="block pl-3 pr-4 py-2 bg-white border-l-4 text-center text-sm rounded-full shadow-xl"
              >
                Sign up as a Cellebrity
              </Link>
              <a
                href="mailto:info@cellebrity.bio"
                className="block pl-3 pr-4 py-2 bg-white border-l-4 text-center text-sm rounded-full shadow-xl"
              >
                Contact us
              </a>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
