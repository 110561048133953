import { authService } from "../../xstate/authMachine";
import { userProfileService } from "../../xstate/userMachine";
import FirebaseClient from "./firebase_client";

export function onAuthStateChange(callback: Function) {
  return FirebaseClient.auth.onAuthStateChanged((user) => {
    if (user) {
      console.log("The user is logged in");
      authService.send({ type: "AUTH_UPDATE", value: user });
      callback(user);
    } else {
      callback(user);
      // if no user, then we are signed out
      authService.send({ type: "SIGNED_OUT" });
      // make sure we've cleared the userProfileService context
      userProfileService.send({ type: "RESET" });
      console.log("The user is not logged in", user);
    }
  });
}
