import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useMutation } from "react-query";
import FirebaseClient from "../../api/firebase/firebase_client";
// @ts-expect-error
import { LazyLoadImage } from "react-lazy-load-image-component";

const JourneyStages = [
  [
    "/about_camera.svg",
    "Image Cells",
    "Your fans can image the cells they have and publish these",
  ],
  [
    "/about_actions.svg",
    "Card Auctions",
    "Your fans bid on the Stem Cards and live auctions keep your audiences engaged",
  ],
  [
    "/about_more_cells.svg",
    "Grow More Cells",
    "Your fans can ‘use’ their card to create more cells that they can then take even more images of!",
  ],
];

const Process = [
  [
    1,
    "You make a one time cheek swab ",
    "You do an at-home cheek swab and ship thet swab to us in a provided bot.",
  ],
  [
    2,
    "We generate your stem cells ",
    "We create your stem cells from your swab sample. All cells remain in our facility where we provide digital access to them.",
  ],
  [
    3,
    "Your Stem Cards go live on your demand",
    "Actions go live and your super fans and players get to strategize around trading the cards.",
  ],
  [
    4,
    "You profit off the trading of your stem cards",
    "You get a percentage on every transaction made involving your cells.",
  ],
];

const Auction = [
  "Fans bid in crypto (ETH) or USD",
  "Auctions run for 24 hours after the minimum reserve price is met",
  "Auction winners get your StemCards and the right to image your cells and add a comment to your cards’ history",
  "Get paid when you sell your cards and everytime your cells are imaged",
];

export const SignUpButton = () => (
  <Link className="block" to="/celeb-sign-up">
    <button
      className="relative bg-red-100 text-sm text-white text-center font-bold rounded-full hover:shadow-xl"
      style={{
        backgroundImage: "url(/about_button_background.png)",
        height: 43,
        width: 225,
        lineHeight: "160%",
      }}
    >
      SIGN UP as a Cellebrity
    </button>
  </Link>
);

export const WelcomePage = () => {
  const [status, setStatus] = useState("");

  const mutation = useMutation(
    async (values: any) => {
      const { email } = values;
      return FirebaseClient.db.collection("emailList").doc().set({ email });
    },
    {
      onSuccess: (data) => {
        setStatus("Received!");
      },
    }
  );
  return (
    <div className="relative overflow-hidden">
      <Helmet>
        <title>Cellebrity.bio</title>
      </Helmet>

      <main className="relative md:mt-24 ">
        {/* HERO Background Image */}
        <img
          className="absolute top-0 right-0 z-0 wfull h-600 md:h-800 lg:h-1000 object-fill"
          src="/about/hero.webp"
          alt=""
        />

        {/* HERO */}
        <section className="z-10 relative md:px-15 lg:px-24 pt-24 md:pt-32 lg:pt-12 mx-auto md:max-w-3xl lg:max-w-7xl">
          {/* Desktop & Tablet */}
          <div className="relative hidden  mx-auto md:grid grid-flow-row-dense grid-cols-1 md:grid-cols-9 lg:grid-cols-12">
            <header className="col-start-1 col-span-5 lg:col-span-7 text-4xl lg:text-6xl">
              <h1 className="font-bold" style={{ lineHeight: "120%" }}>
                Collect & Trade <br />
                Stem Cells of Your <br /> Favorite People
              </h1>
              <p
                className="my-5 max-w-lg lg:my-8 text-base lg:text-lg"
                style={{ color: "rgba(0,0,0,0.8)", lineHeight: "200%" }}
              >
                Digital trading cards backed by your real-world biological stem
                cells. Fans can auction - trade - picture & passage your
                StemCards while your cells stay safe in our systems at all
                times. 🤘
              </p>
              <SignUpButton />
            </header>

            <div className="col-start-6 lg:col-start-8 col-span-4 lg:col-span-5">
              <LazyLoadImage alt="" src="/about_hero_cards.webp" />
              {/* <img
                className="object-cover"
                src="/about_hero_cards.webp"
                alt=""
                // style={{ height: 633.93, width: 512.95 }}
              /> */}
            </div>
          </div>
          {/* Mobile */}
          <div className="md:hidden">
            <header className="block lg:hidden mx-auto max-w-sm px-6 text-center">
              <h1
                className="font-bold"
                style={{ fontSize: 32, lineHeight: "120%" }}
              >
                Collect & Trade <br />
                Stem Cells of Your <br /> Favorite People
              </h1>
            </header>
            <img
              className="lg:hidden w-full object-cover object-center"
              src="/about/card_lineup.webp"
              alt=""
              style={{ height: 259 }}
            />
            <div className="mt-10 flex justify-center">
              <SignUpButton />
            </div>
          </div>
        </section>

        {/* DIGITAL TRADING CARDS  */}
        <div className="pt-20 md:pt-64" />
        <section
          className="relative mx-auto px-6 text-center"
          style={{ maxWidth: 1200 }}
        >
          <h1
            className="mx-auto max-w-2xl text-2xl md:text-4xl font-bold whitespace-nowrap"
            style={{ orphans: 3 }}
          >
            {/* desktop & tablet */}
            <span className="hidden md:block">
              Digital Trading Cards Backed By <br /> Your Real-World Biological
              Stem Cells
            </span>
            {/* mobile */}
            <span className="x:block md:hidden">
              Digital Trading Cards <br /> Backed By Your Real-World <br />{" "}
              Biological Stem Cells
            </span>
            {/* extra small */}
            {/* <span className="xs:hidden md:hidden">
              Digital Trading Cards <br /> Backed By Your <br /> Real-World{" "}
              <br /> Biological Stem Cells
            </span> */}
          </h1>
          <p
            className="mt-4 text-base md:text-lg lg:text-xl mx-auto max-w-2xl"
            style={{ color: "rgba(0,0,0,.6)", lineHeight: "160%" }}
          >
            Stem cells are the universal cell and can turn into any other cell
            type
            <br /> The ultimate celebrity swag
          </p>
          <img
            className="hidden md:block mt-8 mx-auto object-cover w-full"
            style={{ minHeight: 250 }}
            src="/about/card_lineup.webp"
            alt=""
          />
        </section>

        {/* INTRODUCTING STEMCARDS */}
        <section className="relative mx-auto px-6 py-24 lg:py-32 md:max-w-3xl lg:max-w-5xl text-center">
          <h1
            className="text-2xl lg:text-4xl font-bold"
            style={{ lineHeight: "120%" }}
          >
            <span className="hidden md:block">
              Introducing StemCards By Cellebrity.Bio
            </span>
            <span className="md:hidden">
              Introducing StemCards <br /> By Cellebrity.Bio
            </span>
          </h1>
          <div
            className="mt-4 px-6 text-base lg:text-lg"
            style={{
              color: "rgba(0,0,0,.6)",
              lineHeight: "160%",
              orphans: 3,
              widows: 3,
            }}
          >
            <p>
              Each card is unique and tied to a specific population of your
              stem&nbsp;cells
            </p>
            <p>No two cards are the same</p>
            {/* <br />
            Since cells divide as they grow, earlier cells can be much more rare
            than newer cells */}
          </div>

          <div className="pt-9 md:pt-20" />
          {/* Desktop Image */}
          <div className="hidden lg:block mx-auto">
            <LazyLoadImage alt="" src="/about/stem_card.webp" />
          </div>

          {/* <img
            className="hidden lg:block mx-auto"
            src="/about/about_stem_card.png"
            alt=""
          /> */}
          {/* Tablet Image */}
          <img
            className="hidden md:block lg:hidden mx-auto"
            src="/about/stem_card_tablet.webp"
            alt=""
          />
          {/* Mobile Images */}
          <img
            className="block md:hidden mx-auto"
            style={{ maxHeight: 618 }}
            src="/about/stem_card_mobile.webp"
            alt=""
          />
          <div className="block md:hidden mt-14">
            <div>
              <h2 className="text-2xl font-bold" style={{ lineHeight: "120%" }}>
                Stem Cell Picture
              </h2>
              <img
                className="mt-6 mx-auto shadow rounded-full"
                src="/about/cell_emblem.png"
                alt=""
              />
              <p
                className="mt-5 text-base"
                style={{ color: "rgba(0,0,0,0.6)", lineHeight: "160%" }}
              >
                A real picture of the uniquely atteched stemcells to the card
                you purchased. After purchase you know own the right to passage
                - auction and burn the cells.
              </p>
            </div>
            <div className="mt-20">
              <h2 className="text-2xl font-bold" style={{ lineHeight: "120%" }}>
                Stem Cell Picture
              </h2>
              <img
                className="mt-6 mx-auto shadow rounded-b-xl"
                src="/about_card_panel.png"
                alt=""
              />
              <p
                className="mt-5 text-base"
                style={{ color: "rgba(0,0,0,0.6)", lineHeight: "160%" }}
              >
                The unique information attached to each card on Cellebrity.
                These numbers can tell you a bit about the journey of the card
                has been though.
              </p>
            </div>
          </div>
        </section>

        {/* Journey */}
        <div className="pt-28 md:pt-32 lg:pt-44" />
        <section className="relative z-10 px-6 mx-auto max-w-xs md:max-w-3xl lg:max-w-5xl text-center">
          <img className="mx-auto" src="/about/nft_icon.svg" alt="" />
          <header className="mt-6">
            <h1
              className="text-2xl lg:text-4xl font-bold"
              style={{ lineHeight: "160%" }}
            >
              <span className="hidden md:block">
                Every Card is a NFT and has A Unique Journey
              </span>
              <span className="md:hidden">
                Every Card is a NFT and <br /> has A Unique Journey
              </span>
            </h1>
            <p
              className="mt-4 text-base lg:text-2xl"
              style={{ color: "rgba(0,0,0,.6)", lineHeight: "160%" }}
            >
              Whoever owns your card can image, bid, & passage the card’s cells
              and add a comment. Create a living tapestry of fan engagement
              around your biology
            </p>
          </header>
          <ul className="relative mt-13 space-y-9 md:space-y-0 grid grid-cols-1 md:grid-cols-3">
            {JourneyStages.map((item) => {
              return (
                <div className="" key={item[0]}>
                  <img className="mx-auto" src={item[0]} alt="" />
                  <div
                    className="mt-1 text-2xl font-bold"
                    style={{ lineHeight: "120%" }}
                  >
                    {item[1]}
                  </div>
                  <div
                    className="mt-3 mx-auto max-w-xs text-base"
                    style={{ color: "rgba(0,0,0,.6)", lineHeight: "160%" }}
                  >
                    {item[2]}
                  </div>
                </div>
              );
            })}
          </ul>
          {/* Desktop */}
          <div className="hidden lg:block mt-44">
            <LazyLoadImage alt="" src="/about/card_journey.png" />
          </div>
          {/* <img
            className="hidden lg:block mt-44"
            src="/about/card_journey.png"
            alt=""
          /> */}
          {/* Tablet */}
          <div className="hidden md:block lg:hidden mt-20">
            <LazyLoadImage
              alt=""
              src="/about/card_journey_tablet.png" // use normal <img> attributes as props
            />
          </div>
          {/* <img
            className="hidden md:block lg:hidden mt-44"
            src="/about/about_journey_tablet.png"
            alt=""
          /> */}
        </section>

        {/* AUCTION */}
        <section className="relative -mt-36">
          <img
            className="absolute z-0 top-0 left-0 w-full h-1800 lg:h-1800 object-auto"
            src="/about_bg_2.webp"
            alt=""
          />
          {/* This spacing 'undoes' the negative margin of the section */}
          <div className="pt-36" />
          {/* We can then adjust margins from figma  */}
          <section className="relative z-10 md:px-0 pt-20 mx-auto max-w-3xl lg:max-w-5xl lg:grid grid-cols-2 text-center lg:text-left">
            <img
              className="md:hidden mx-auto object-cover"
              style={{ width: 389.12, height: 364.32 }}
              src="/about_auction.webp"
              alt=""
            />
            <header className="px-2 mx:px-0">
              <h1
                className="text-2xl md:text-5xl font-bold"
                style={{ lineHeight: "160%" }}
              >
                <span className="hidden lg:block">
                  Auction Your <br /> StemCards For <br /> Fun & Profit
                </span>
                <span className="block lg:hidden">
                  Auction Your StemCards <br /> For Fun & Profit
                </span>
              </h1>
              <p
                className="hidden md:block text-xl md:text-2xl"
                style={{ color: "rgba(0,0,0,0.8)", lineHeight: "160%" }}
              >
                Discover who your true fans really are
              </p>
            </header>
            {/*  */}
            <img
              className="hidden md:block mx-auto object-cover"
              style={{ width: 389.12, height: 364.32 }}
              src="/about_auction.webp"
              alt=""
            />
            {/*  */}
            <div className="col-span-2 mt-10">
              <header className="md:hidden text-center lg:text-left">
                <p
                  className="px-6 text-2xl mx-auto max-w-4xl"
                  style={{ color: "rgba(0,0,0,0.8)", lineHeight: "160%" }}
                >
                  Discover who your true fans really are and generate some nice
                  new income
                </p>
                <div className="pt-7" />
                <SignUpButton />
              </header>
              <ul className="mt-13 px-8 md:px-0 space-y-2 md:space-y-0 md:mt-2 ml5 text-base md:text-lg text-left md:text-center lg:text-left">
                {Auction.map((item) => (
                  <li
                    className="block"
                    style={{ color: "rgba(0,0,0,.6)", lineHeight: "160%" }}
                    key={item}
                  >
                    • {item}
                  </li>
                ))}
              </ul>
            </div>
          </section>

          {/* DID YOU KNOW */}
          <div className="pt-28 lg:pt-36" />
          <section
            className="p-6 mx-auto md:max-w-3xl lg:max-w-5xl md:grid grid-cols-2 md:gap-4 text-black text-left"
            // style={{ maxWidth: 1200 }}
          >
            <div className="p-5 md:p-8 bg-white opacity-60 backdrop-filter backdrop-blur rounded-xl">
              <h1
                className="text-2xl md:text-xl lg:text-3xl font-bold"
                style={{ lineHeight: "120%" }}
              >
                Stem Cells Can Turn&nbsp;Into <br /> Any Other Type of Cell
              </h1>
              <p
                className="mt-4 text-base lg:text-lg"
                style={{ lineHeight: "160%" }}
              >
                Stem Cells are the first type of cells. Stem cells have the
                awesome potential to turn into any other cell type in your body
                such as liver, heart, and even brain cells! Stem cells can also
                make more of themselves indefinitely.{" "}
              </p>
            </div>
            {/*  */}
            <div className="hidden md:block p-8 bg-white opacity-60 backdrop-filter backdrop-blur rounded-xl">
              <h1
                className="text-xl lg:text-3xl font-bold"
                style={{ lineHeight: "120%" }}
              >
                All It Takes Is a One-Time <br /> At-Home Cheek Swab
              </h1>
              <p
                className="mt-4 text-base lg:text-lg"
                style={{ lineHeight: "160%" }}
              >
                All you need to do do is an at-home cheek swab. Then ship us
                that swap in a specialized bot that keeps those precious cells
                alive on the way to our labs. Once in our labs, these cheek
                cells undergo a process called ‘dedifferentiation’ where they
                are turned back into stem cells (remember, all cells start out
                as stem cells!).
              </p>
            </div>
          </section>
          {/*  */}
          <div className="py12 lg:pb-56" />
        </section>

        {/* GET STARTED */}
        <section
          className="relative z-10 mx-auto -mt-24 px-6"
          style={{ maxWidth: 1200 }}
        >
          {/* This spacing undoes the above negative margin */}
          <div className="pt-24" />
          <header className="pt-32 grid grid-cols-5 md:grid-cols-9 lg:grid-cols-12">
            <img
              className="hidden lg:block col-span-8"
              src="/about/get_started.png"
              alt=""
            />
            <img
              className="mx-auto hidden md:block lg:hidden col-span-5 col-start-3"
              src="/about/get_started_tablet.png"
              alt=""
            />
            <img
              className="md:hidden col-span-4"
              src="/about/get_started_mobile.png"
              alt=""
            />
          </header>
          <ul className="pt-24 space-y-20 lg:space-y-0 grid grid-cols-1 plac-items-center lg:grid-cols-4 lg:gap-4 text-left md:text-center lg:text-left">
            {Process.map((item, i) => {
              return (
                <div className="" key={item[0]}>
                  {/* <img className="" src={`/about/${i + 1}.png`} alt="" /> */}
                  {/* <img className="" src={`/about/${i + 1}.png`} alt="" /> */}
                  {/* <img className="" src={`/about/${i + 1}.png`} alt="" /> */}

                  <div className="flex md:justify-center lg:justify-start">
                    <img className="" src={`/numbers/${item[0]}.png`} alt="" />
                  </div>
                  {/* <div
                    className="text-7xl text-gray-500 font-bold"
                    style={{
                      fontFamily: "Helvetica Neue",
                      fontSize: 80,
                      color: "rgba(0,0,0,0.2)",
                    }}
                  >
                    {item[0]}
                  </div> */}
                  <div
                    className="mt-8 text-2xl font-bold"
                    style={{ lineHeight: "120%" }}
                  >
                    {item[1]}
                  </div>
                  <div
                    className="mt-4 text-base"
                    style={{ color: "rgba(0,0,0,0.6)", lineHeight: "160%" }}
                  >
                    {item[2]}
                  </div>
                </div>
              );
            })}
          </ul>
        </section>

        {/* FOOTER - COLLECT & TRADE */}
        <div className="pt-28 md:pt-40 lg:pt-48" />
        <section
          className="relative py-24 bg-black w-full text-white text-center"
          // style={{ height: 553 }}
        >
          <div className="hidden lg:block absolute z-0 bottom-0 left-0">
            <LazyLoadImage alt="" src="/about/living_cell.webp" />
          </div>
          {/* <img
            className="hidden lg:block absolute z-0 bottom-0 left-0"
            src="/about/living_cell.webp"
            alt=""
          /> */}
          <div className="absolute lg:hidden z-0 top-0 left-0">
            <LazyLoadImage alt="" src="/about/living_cell_mobile.webp" />
          </div>
          {/* <img
            className="absolute lg:hidden z-0 top-0 left-0"
            src="/about/living_cell_mobile.webp"
            alt=""
          /> */}
          <div className="relative z-10 mx-auto max-w-md space-y-14">
            <div className="flex flex-col xs:flex-row items-center justify-center">
              <img className="h-14 lg:h-16" src="/logo.svg" alt="" />
              <h1 className="text-5xl">Cellebrity.bio</h1>
            </div>
            <h1
              className="mt-8 text-2xl lg:text-5xl font-bold"
              style={{ lineHeight: "120%" }}
            >
              <span className="hidden lg:block">
                Collect & Trade <br /> Stem Cells of Your <br /> Favorite People
              </span>
              <span className="block lg:hidden">
                Collect & Trade Stem Cells <br /> of Your Favorite People
              </span>
            </h1>
            {/*  */}
            <SignUpButton />
            <a
              className="inline-block text-sm"
              href="mailto:info@cellebrity.bio"
            >
              info@cellebrity.bio
            </a>

            <div className="flex justify-center items-center">
              <a
                className="flex items-center text-sm"
                href="https://twitter.com/cellebritybio"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="" src="/about/twitter.svg" alt="twitter logo" />
              </a>
            </div>
            {/*  */}
            {/* <Formik
              initialValues={{
                email: "",
              }}
              onSubmit={(values, { setSubmitting }: FormikHelpers<any>) => {
                mutation.mutate(values);
              }}
            >
              <Form>
                <div className="flex items-center mt-20 w-full bg-white rounded-full ">
                  <Field
                    className="text-xs flex-grow border-white rounded-full text-black"
                    name="email"
                    id="email"
                    type="email"
                    placeholder="email"
                    autoComplete="email"
                  />
                  <button className="m-1 px-12 py-2 bg-skyblue h-full text-sm text-white rounded-full uppercase">
                    {mutation.isLoading && <Spinner />}
                    Submit
                  </button>
                </div>
                <div className="text-white">{status}</div>
              </Form>
            </Formik> */}
            {/* <SignUpButton /> */}
            {/* <a className="mt-10 block" href="mailto:info@cellebrity.bio">
          info@cellebrity.bio
        </a> */}
          </div>
        </section>
      </main>
    </div>
  );
};

export default WelcomePage;
