import { assign, interpret, Machine } from "xstate";
import { UserProfile } from "../types/user_profiles";

// export type LineItem = {
//   id: string;
//   product: any; //the Stripe product info
//   meta: any; //additional metadata that we need
//   ref: any;
// };

// if (process.env.NODE_ENV === "development") {
//   inspect({
//     iframe: false, // open in new window
//   });
// }

interface UserContext {
  profile: UserProfile | undefined; //to put the /users/{uid}/home/profile values
  uid: string | undefined;
  username: string | undefined;
  currentUser: boolean; //true if profile is the currently signed-in user
}

const makeInitialContext = (): UserContext => ({
  profile: undefined,
  username: undefined,
  uid: undefined,
  currentUser: false,
});

type UserEvent =
  | { type: "SIGN_IN" }
  | { type: "SIGNED_IN" }
  | { type: "SIGN_OUT" }
  | { type: "EDIT" }
  | { type: "CANCEL" }
  | { type: "PROFILE_UPDATE"; value: UserProfile }
  | { type: "AUTH_UPDATE"; value: any }
  | { type: "GET_USER"; value: any }
  | { type: "RESET" };

// NB: things that are read-only can just use the react-query cache.
// it's only for read/write parts of the system that we need FSM?
// this seems correct

// TODO: think of the states more in the sense of CRUD
// need: view, edit, etc states
// TODO: make this just a userMachine so that it can hold other aspects of the user system. this is the root notes for the all the user's state machines?
export const userProfileMachine = Machine<UserContext, any, any>(
  {
    id: "userMachine",
    context: {
      profile: undefined,
      uid: undefined,
      username: undefined,
      currentUser: false,
    },
    initial: "waiting",

    states: {
      waiting: {
        //no user. waiting for a sign-in event
        on: {
          SIGN_IN: "pending",
          EDIT: "editing",
        },
      },
      pending: {
        //Signing in
        // always: "ready",
        on: {
          SIGNED_IN: "ready",
          // UPDATED: "ready",
        },
      },
      signing_in: {
        // target: "ready",
      },
      signing_out: {},
      updating: {},
      cancelled: {
        entry: ["waiting"],
      },
      editing: {
        //in edit mode at /profile page
        on: {
          CANCEL: "waiting",
          PROFILE_UPDATE: {
            // entry: assign({})
            // transitions to pending on initial react-query call
            // transitions to ready on react-query resolution
            target: "waiting",
            actions: [
              assign({
                profile: (context, event) => {
                  console.log("PROFILE_UPDATE FSM", event);
                  return event.value;
                },
              }),
              "persist",
            ],
            // target: "updating",
          },
        },
      },

      ready: {
        //signed in user. can accept user.profile actions
      },
    },
    on: {
      PROFILE_UPDATE: {
        target: "ready",
        actions: [
          assign((context, event) => {
            console.log("Update profile", event);
            return { ...event.value };
          }),
          "persist",
        ],
      },
      RESET: {
        target: "waiting",
        actions: [
          assign((context, event) => {
            console.log("RESET EVENT FSM", event);
            return makeInitialContext();
          }),
          "persist",
        ],
      },
    },
  },

  // Config Options
  {
    actions: {
      persist: (ctx: any) => {
        try {
          // TODO: need to diff btw currentUser and just a normal profile
          // TODO: YAGNI this. don't need FSM for general profile case (yet)
          localStorage.setItem("stembionix-user", JSON.stringify(ctx));
        } catch (error) {
          console.error(error);
          return [];
        }
      },
    },
  }
  // Initial State. Can I just do all of context at once?
  // {
  //   auth: (() => {
  //     try {
  //       const stored = JSON.parse(
  //         localStorage.getItem("stembionix-profile") || "[]"
  //       );
  //       return stored;
  //     } catch (error) {
  //       console.error(error);
  //       return [];
  //     }
  //   })(),
  //   profile: (() => {
  //     try {
  //       const stored = JSON.parse(
  //         localStorage.getItem("stembionix-profile") || "[]"
  //       );
  //       return stored;
  //     } catch (error) {
  //       console.error(error);
  //       return [];
  //     }
  //   })(),
  // }
);

const service = interpret(userProfileMachine, {
  devTools: process.env.NODE_ENV === "development",
});
service.start();
export { service as userProfileService };
export default service;
