import React, { Component, Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import AboutPage from "../pages/marketing/AboutPage";
import { CelebSignUp } from "../pages/marketing/CelebSignUp";

import { ErrorBoundary } from "./ErrorBoundary";

// ABOUT AND MARKETING -------------------------------------------------------//

export class Router extends Component {
  state = {
    hasError: false,
  };

  componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true });
    if (process.env.NODE_ENV !== "production") {
      console.group("ROUTER ERROR: COMPONENT DID CATCH");
      console.error(error, info);
      console.groupEnd();
    }
  }

  render() {
    return (
      <ErrorBoundary>
        <Switch>
          <Route exact path="/" component={AboutPage} />
          <Route exact path="/celeb-sign-up" component={CelebSignUp} />

          <Route path="/:slug">
            <Redirect to="/" />
          </Route>
        </Switch>
      </ErrorBoundary>
    );
  }
}
