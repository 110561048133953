import React, { useState } from "react";
import { Formik, Field, Form, FormikHelpers } from "formik";
import { useMutation } from "react-query";

import FirebaseClient from "../../api/firebase/firebase_client";
import { Spinner } from "../../components/network_state/Spinner";

export const CelebSignUp = () => {
  const [status, setStatus] = useState("");
  const mutation = useMutation(
    async (values: any) => {
      const { email, type, name, message } = values;
      return FirebaseClient.db
        .collection("basicEnquires")
        .doc()
        .set({ email, type, name, message });
    },
    {
      onSuccess: (data) => {
        setStatus("You’ll hear from us soon! 🤘");
      },
    }
  );
  if (!!status) {
    return (
      <div
        className="relative mt-80 text-center text-4xl font-bold"
        style={{ color: "rgba(0,0,0,0.8)", lineHeight: "120%" }}
      >
        {status}
      </div>
    );
  }

  return (
    <div
      className="relative h-screen pt-32 md:pt-64 px-6 w-full bg-cover bg-no-repeat"
      style={{ backgroundImage: "url(/sign_up_bg.png)" }}
    >
      <div className="mx-auto max-w-3xl">
        <header className="">
          <h1
            className="text-center text-6xl font-bold"
            style={{ lineHeight: "120%" }}
          >
            Sign up as a Cellebrity
          </h1>
        </header>
        <Formik
          initialValues={{
            type: "celeb",
            email: "",
            name: "",
            message: "",
          }}
          onSubmit={(values, { setSubmitting }: FormikHelpers<any>) => {
            mutation.mutate(values);
          }}
        >
          <Form>
            {/* NAME */}
            <div className="mt-16">
              <label
                htmlFor="name"
                className="sr-only block text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <div className="mt-1">
                <Field
                  type="text"
                  name="name"
                  id="name"
                  autoComplete="name"
                  className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300 rounded-full"
                  placeholder="Full name"
                />
              </div>
            </div>
            {/* EMAIL */}
            <div className="mt-3">
              <label
                htmlFor="email"
                className="sr-only block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <div className="mt-1">
                <Field
                  type="text"
                  name="email"
                  id="email"
                  autoComplete="email"
                  className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300 rounded-full"
                  placeholder="Email"
                />
              </div>
            </div>
            {/* MESSAGE */}
            <div className="mt-3">
              <label
                htmlFor="message"
                className="sr-only block text-sm font-medium text-gray-700"
              >
                Leave us a message
              </label>
              <div className="mt-1">
                <Field
                  as="textarea"
                  type="textarea"
                  name="message"
                  id="message"
                  rows={4}
                  className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300 rounded-xl"
                  placeholder="Leave us a message!"
                />
              </div>
            </div>

            <div className="flex justify-end mt-2 rounded-full">
              <button
                className="h-10 w-40 bg-skyblue text-white text-sm font-bold uppercase rounded-full hover:shadow-xl"
                type="submit"
              >
                {mutation.isLoading && <Spinner />}
                Submit
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};
