import { UserProfile } from "../../types/user_profiles";
import { userProfileService } from "../../xstate/userMachine";
import FirebaseClient from "./firebase_client";

export function onProfileChange(callback: Function) {
  return FirebaseClient.db
    .collection("/users")
    .doc(FirebaseClient.auth.currentUser?.uid)
    .onSnapshot((snapshot) => {
      const data = { id: snapshot.id, ...snapshot.data() } as UserProfile;

      userProfileService.send({ type: "PROFILE_UPDATE", value: data });

      if (process.env.NODE_ENV !== "production") {
        console.log(
          "Current profile new snapshot",
          data,
          userProfileService.state.context
        );
      }

      callback(data);
    });
}
