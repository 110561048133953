import firebase from "firebase/app";
import { inspect } from "@xstate/inspect";
import { assign, interpret, Machine } from "xstate";

// if (process.env.NODE_ENV === "development") {
//   inspect({
//     iframe: false, // open in new window
//   });
// }

interface AuthContext {
  user: {
    uid: string | undefined;
    displayName: string | null | undefined;
    email: string | null | undefined;
    signedIn: boolean;
  };
}

const makeInitialContext = (): AuthContext => ({
  user: {
    uid: undefined,
    displayName: undefined,
    email: undefined,
    signedIn: false,
  },
});

type AuthEvent =
  | { type: "SIGNED_IN"; value: firebase.auth.UserCredential }
  | { type: "SIGNED_OUT" }
  | { type: "AUTH_UPDATE"; value: firebase.User };

// FIXME: there's a bug when signing out and creating a new account; the signed-out account isn't properly cleared. get into a bad state
export const authMachine = Machine<AuthContext, any, AuthEvent>(
  {
    id: "authMachine",
    context: makeInitialContext(),
    initial: "signed_out",
    states: {
      signed_out: {
        //no user. waiting for a sign-in event
        on: {
          SIGNED_IN: {
            target: "signed_in",
            actions: [
              assign({
                user: (context, event) => {
                  console.log("SIGNED IN EVENT FSM", event);
                  return {
                    uid: event.value.user?.uid,
                    displayName: event.value.user?.displayName,
                    email: event.value.user?.email,
                    signedIn: true,
                  };
                },
              }),
              "persist",
            ],
          },
          AUTH_UPDATE: {
            actions: [
              assign({
                user: (context, event) => {
                  console.log("AUTH_UPDATE USER FSM", event);
                  return {
                    uid: event.value.uid,
                    signedIn: !!event.value.uid,
                    displayName: event.value.displayName,
                    email: event.value.email,
                  };
                },
              }),
              "persist",
            ],
          },
        },
      },
      signed_in: {
        // always: "ready",
        on: {
          SIGNED_OUT: {
            target: "signed_out",
            actions: [
              assign((context, event) => {
                console.log("SIGNED OUT EVENT FSM", event);
                return makeInitialContext();
              }),
              "persist",
            ],
          },
          AUTH_UPDATE: {
            actions: [
              assign({
                user: (context, event) => {
                  console.log("AUTH_UPDATE USER FSM", event);
                  return {
                    uid: event.value.uid,
                    signedIn: !!event.value.uid,
                    displayName: event.value.displayName,
                    email: event.value.email,
                  };
                },
              }),
              "persist",
            ],
          },
        },
      },
    },
    on: {
      SIGNED_OUT: {
        target: "signed_out",
        actions: [
          assign((context, event) => {
            console.log("SIGNED OUT EVENT FSM", event);
            return makeInitialContext();
          }),
          "persist",
        ],
      },
    },
  },
  // Config Options
  {
    actions: {
      persist: (ctx: any) => {
        try {
          localStorage.setItem("stembionix-auth", JSON.stringify(ctx.user));
        } catch (error) {
          console.error(error);
          return {};
        }
      },
    },
  },
  // Initial State
  {
    user: (() => {
      try {
        const stored = localStorage.getItem("stembionix-auth");
        if (!stored) {
          return makeInitialContext();
        }
        console.log("Loading auth state from local storage");
        const parsed = JSON.parse(stored);
        return parsed;
      } catch (error) {
        console.error(error);
        return makeInitialContext();
      }
    })(),
  }
);

const service = interpret(authMachine, {
  devTools: process.env.NODE_ENV === "development",
});
service.start();
export { service as authService };
export default service;
