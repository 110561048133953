import React, { useEffect, useState } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import { QueryClientProvider } from "react-query";

import FirebaseClient from "./api/firebase/firebase_client";

import { Router } from "./router/Router";
import { onAuthStateChange } from "./api/firebase/on_auth_change";
import { onProfileChange } from "./api/firebase/on_profile_change";
import { queryClient } from "./react_query/query_client";
import { NavBar, MobileNavBar } from "./pages/marketing/NavBar";

// Hack to make typescript not yell about window.ethereum
declare global {
  interface Window {
    ethereum: any;
  }
}

function PageViews() {
  let location = useLocation();
  React.useEffect(() => {
    FirebaseClient.analytics.logEvent("page_view", {
      page_location: location.pathname,
      page_path: window.location.pathname,
    });
  }, [location]);

  return <></>;
}

export const App = () => {
  const [user, setUser] = useState(FirebaseClient.auth?.currentUser);
  const [, setProfile] = useState<any>();

  useEffect(() => {
    const unsubscribe = onAuthStateChange(setUser);
    return () => {
      unsubscribe();
    };
  }, []);

  // TODO: move profile patterns to just firebase/react-query; don't need xstate
  useEffect(() => {
    if (!user) return;
    const unsubscribe = onProfileChange(setProfile);
    return () => {
      unsubscribe();
    };
  }, [user]);

  return (
    <div className="font-raleway">
      <BrowserRouter>
        <PageViews />
        <NavBar />
        <MobileNavBar />
        <QueryClientProvider client={queryClient}>
          <Router />
        </QueryClientProvider>
      </BrowserRouter>
    </div>
  );
};

export default App;
